.games-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #c2f37b;
}
.games-hero {
  height: 1842px;
  position: relative;
  align-items: flex-start;
  margin-bottom: 403px;
  justify-content: flex-start;
}
.games-container01 {
  top: 7px;
  flex: 0 0 auto;
  right: -2px;
  width: 488px;
  height: 461px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}
.games-link {
  text-decoration: none;
}
.games-container02 {
  top: 451px;
  flex: 0 0 auto;
  right: -9px;
  width: 488px;
  height: 461px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text03 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}
.games-link01 {
  text-decoration: none;
}
.games-container03 {
  top: 917px;
  flex: 0 0 auto;
  right: -9px;
  width: 488px;
  height: 461px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text06 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}
.games-link02 {
  text-decoration: none;
}
.games-container04 {
  flex: 0 0 auto;
  right: -10px;
  width: 488px;
  bottom: -12px;
  height: 461px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text09 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}
.games-link03 {
  text-decoration: none;
}
.games-container05 {
  flex: 0 0 auto;
  width: 488px;
  height: 461px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text10 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}
.games-link04 {
  text-decoration: none;
}
.games-container06 {
  flex: 0 0 auto;
  width: 488px;
  height: 461px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text11 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}
.games-link05 {
  text-decoration: none;
}
.games-container07 {
  flex: 0 0 auto;
  width: 488px;
  height: 461px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text12 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}
.games-link06 {
  text-decoration: none;
}
.games-container08 {
  flex: 0 0 auto;
  width: 488px;
  height: 461px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text15 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}
.games-link07 {
  text-decoration: none;
}
.games-container09 {
  top: 451px;
  flex: 0 0 auto;
  right: -9px;
  width: 488px;
  height: 461px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text18 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}
.games-link08 {
  text-decoration: none;
}
.games-container10 {
  top: 1829px;
  flex: 0 0 auto;
  left: 357px;
  width: 488px;
  height: 461px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.games-text21 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}
.games-link09 {
  text-decoration: none;
}
