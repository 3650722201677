.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-navbar {
  height: 65px;
}
.home-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image {
  width: 22px;
  object-fit: cover;
  margin-right: 14px;
}
.home-links {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-text {
  color: var(--dl-color-scheme-green80);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-text01 {
  color: var(--dl-color-scheme-green80);
  margin-right: var(--dl-space-space-twounits);
}
.home-text02 {
  color: var(--dl-color-scheme-green80);
  margin-right: var(--dl-space-space-twounits);
}
.home-link {
  text-decoration: none;
}
.home-link1 {
  text-decoration: none;
}
.home-burger-menu {
  fill: #105749;
  width: 24px;
  height: 24px;
}
.home-hero {
  height: 751px;
}
.home-max-width1 {
  align-items: center;
}
.home-content {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-title {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text04 {
  color: var(--dl-color-scheme-lightgreen);
}
.home-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-navlink {
  text-decoration: none;
}
.home-image1 {
  position: relative;
  padding-bottom: var(--dl-space-space-eightunits);
}
.home-hero-image {
  flex: 1;
  width: 575px;
  height: 625px;
  max-width: 550px;
  object-fit: cover;
  border-radius: 48px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.home-graphic-top {
  top: 0px;
  right: -170px;
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 359px;
  max-height: 359px;
  object-fit: contain;
  object-position: center;
}
.home-image2 {
  right: -170px;
  bottom: 0px;
  position: absolute;
  object-fit: contain;
}
.home-section {
  height: 811px;
}
.home-max-width2 {
  align-items: stretch;
}
.home-image3 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
}
.home-hero-image1 {
  width: 698px;
  height: 607px;
  object-fit: contain;
  margin-left: -250px;
  border-radius: var(--dl-radius-radius-round);
}
.home-content1 {
  width: 908px;
  display: flex;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text05 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text06 {
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-navlink1 {
  text-decoration: none;
}
.home-section1 {
  height: 824px;
  background-color: rgba(255, 199, 139, 0.14);
}
.home-max-width3 {
  align-items: stretch;
}
.home-content2 {
  width: 50%;
  display: flex;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text11 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text12 {
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text15 {
  font-family: Urbanist;
}
.home-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-hero-image2 {
  width: 772px;
  height: 651px;
  object-fit: contain;
  margin-right: -250px;
  border-radius: var(--dl-radius-radius-round);
}
.home-section2 {
  background-color: var(--dl-color-scheme-green100);
}
.home-max-width4 {
  align-items: stretch;
}
.home-image5 {
  width: 50%;
}
.home-video {
  width: 597px;
  height: 385px;
}
.home-content3 {
  width: 40%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text17 {
  color: var(--dl-color-scheme-white);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text18 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-step {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-number {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-orange100);
}
.home-text23 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
.home-container4 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title1 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-step1 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-number1 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-orange100);
}
.home-text24 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
.home-container5 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title2 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-step2 {
  display: flex;
  flex-direction: row;
}
.home-number2 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-orange100);
}
.home-text25 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
.home-container6 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title3 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-section3 {
  padding-top: 0px;
}
.home-max-width5 {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-f-a-q {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-eightunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-questions {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text26 {
  color: var(--dl-color-scheme-green80);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text27 {
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text28 {
  font-family: Urbanist;
}
.home-text30 {
  color: var(--dl-color-scheme-orange100);
  font-family: Urbanist;
}
.home-trigger {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-text31 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
}
.home-icon02 {
  width: 12px;
  height: 12px;
}
.home-text32 {
  margin-top: 8px;
}
.home-trigger1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text33 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
}
.home-icon04 {
  width: 12px;
  height: 12px;
}
.home-text34 {
  margin-top: 8px;
}
.home-trigger2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-text35 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
}
.home-icon06 {
  width: 12px;
  height: 12px;
}
.home-text36 {
  margin-top: 8px;
}
.home-trigger3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-text37 {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.5;
}
.home-icon08 {
  width: 12px;
  height: 12px;
}
.home-text38 {
  margin-top: 8px;
}
.home-image6 {
  width: 610px;
  height: 648px;
  object-fit: cover;
  margin-right: -200px;
}
.home-banner {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  border-radius: 48px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/group%2011-1200w.png");
}
.home-text39 {
  color: var(--dl-color-scheme-lightgreen);
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text40 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text41 {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-btns {
  flex: 0 0 auto;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-navlink2 {
  padding-top: 12px;
  margin-right: 0px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 12px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .home-content {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .home-image1 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-hero-image {
    margin-bottom: 0px;
  }
  .home-max-width2 {
    flex-direction: column;
  }
  .home-image3 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-hero-image1 {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .home-content1 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-max-width3 {
    flex-direction: column;
  }
  .home-content2 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-image4 {
    padding-bottom: 0px;
  }
  .home-hero-image2 {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .home-max-width4 {
    flex-direction: column;
  }
  .home-image5 {
    width: 100%;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-content3 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-max-width5 {
    flex-direction: column;
  }
  .home-questions {
    width: 60%;
  }
  .home-image6 {
    margin-right: -100px;
  }
}
@media(max-width: 767px) {
  .home-navbar {
    height: auto;
  }
  .home-links {
    display: none;
  }
  .home-icon {
    fill: var(--dl-color-scheme-green100);
  }
  .home-max-width1 {
    flex-direction: column-reverse;
  }
  .home-content {
    width: 100%;
    margin-right: 0px;
  }
  .home-hero-image {
    margin-right: 0px;
  }
  .home-content1 {
    width: 100%;
    margin-right: 0px;
  }
  .home-content2 {
    width: 100%;
    margin-right: 0px;
  }
  .home-content3 {
    width: 100%;
    margin-right: 0px;
  }
  .home-f-a-q {
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-questions {
    width: 100%;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-image6 {
    width: 100%;
    margin-right: 0px;
  }
  .home-banner {
    padding: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .home-hero-image {
    width: auto;
  }
  .home-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
